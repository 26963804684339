<template>
    <div v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register')" class="footer_wrap mt-auto" ref="footer">
        <!-- Footer Start -->
        <div class="app-footer">
            <b-row align-h="between" class="">
                <b-col cols="" class="position-relative col-auto">
                    <router-link tag="a" to="/web/homepage" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'homepage', 'inactive': $parent.path != 'homepage' }">
                            <img :src="$parent.path == 'homepage' ? require('./assets/images/flx_footer/home_active.png') : require('./assets/images/flx_footer/home_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>
                <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/wallet" class="nav-item-hold ">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'wallet', 'inactive': $parent.path != 'wallet' }">
                            <img :src="$parent.path == 'wallet' ? require('./assets/images/flx_footer/wallet_active.png') : require('./assets/images/flx_footer/wallet_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>

                <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/trade" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'trade', 'inactive': $parent.path != 'trade' }">
                            <img :src="$parent.path == 'trade' ? require('./assets/images/flx_footer/trade_active.png') : require('./assets/images/flx_footer/trade_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>

                <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/ib_tree" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'ib_tree', 'inactive': $parent.path != 'ib_tree' }">
                            <img :src="$parent.path == 'ib_tree' ? require('./assets/images/flx_footer/tree_active.png') : require('./assets/images/flx_footer/tree_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>

                <b-col cols="" class="col-auto">
                    <router-link tag="a" to="/web/commission" class="nav-item-hold">
                        <div :class="{ 'nav-icon': true, 'active': $parent.path == 'commission', 'inactive': $parent.path != 'commission' }">
                            <img :src="$parent.path == 'commission' ? require('./assets/images/flx_footer/commission_active.png') : require('./assets/images/flx_footer/commission_icon.png')"
                                class="nav-icon" />
                        </div>
                    </router-link>
                </b-col>
            </b-row>
            <!-- fotter end -->
        </div>
    </div>

</template>
<script>
export default{

}
</script>